var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-changelog-sidebar","visible":_vm.isUpdateChangelogSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-update-changelog-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Modification d'un changelog")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),(!_vm.loadingChangelog)?_c('validation-observer',{ref:"createFormValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Application","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"application"}},[_vm._v(" Application : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-select',{attrs:{"options":_vm.changelogApplication},model:{value:(_vm.changelog.application),callback:function ($$v) {_vm.$set(_vm.changelog, "application", $$v)},expression:"changelog.application"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"description du nouveau changelog","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"region"}},[_vm._v("Description : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('ressifnet-quill-editor',{model:{value:(_vm.changelog.description),callback:function ($$v) {_vm.$set(_vm.changelog, "description", $$v)},expression:"changelog.description"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"changelogType"}},[_vm._v(" Type de changelog "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('searchable-vue-select',{attrs:{"id":"changelogType","label":"libelle","placeholder":"Choisissez un type de changelog","options":_vm.listChangelogTypes.items,"hasNextPage":_vm.listChangelogTypes.items.length <
                _vm.listChangelogTypes.totalCount,"loadOnCreate":true,"reduce":function (option) { return option.id; }},on:{"load-next-page":function (params) { return _vm.searchChangelogTypes(params); }},model:{value:(_vm.changelog.typeId),callback:function ($$v) {_vm.$set(_vm.changelog, "typeId", $$v)},expression:"changelog.typeId"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._l((_vm.listTenants),function(tenant){return _c('b-form-checkbox',{key:tenant.id,staticClass:"mb-1 mr-1",attrs:{"name":tenant.name,"switch":"","value":tenant.id},model:{value:(_vm.changelog.tenantsIds),callback:function ($$v) {_vm.$set(_vm.changelog, "tenantsIds", $$v)},expression:"changelog.tenantsIds"}},[_vm._v(" "+_vm._s(tenant.name)+" ")])}),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Chargement...","variant":"primary"}})],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Enregistrer ")]),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Annuler ")])],1):_vm._e()],2)]}}],null,true)}):_vm._e(),(_vm.loadingChangelog)?_c('div',{staticClass:"text-center mt-2"},[_c('b-spinner',{attrs:{"label":"Chargement...","variant":"primary"}})],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }