








































































import { quillEditor } from 'vue-quill-editor'
import { Component, Prop, Vue } from "vue-property-decorator";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

@Component({
  components: {
    quillEditor
  },
  name: "ressifnet-quill-editor",
})
export default class RessifnetQuillEditor extends Vue {
  random  = this.getRandom()

  snowOption: any = {
    theme: 'snow',
    placeholder: 'Saississez votre texte',
    modules: {
      toolbar: `#${this.random}`
    }
  }

  getRandom() {
    let one = Math.random()*10000
    let two = Math.random()*10000
    let three = Math.random()*10000

    return "toolbar-" + Math.floor(one + three * two);
  }
}
