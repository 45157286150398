
































































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
  BFormTextarea
} from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { Changelog, UpdateChangelogModel } from "@/api/models/changelogs/changelog";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { ShortTenant } from "@/api/models/tenants/tenant";
import { ChangelogType } from "@/api/models/changelogs/changelogType";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { ChangelogApplication } from "@/api/models/enums/changelogApplication";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    RessifnetQuillEditor
  },
  name: "update-changelog",
})
export default class EditChangelog extends Vue {
  @Prop({ required: true, type: Boolean }) isUpdateChangelogSidebarActive!: boolean;
  @Prop({ required: true }) changelogId!: string;

  changelogApplication = ChangelogApplication

  loading = false;
  required = required;
  loadingChangelog = false;

  listChangelogTypes = new PaginatedList<ChangelogType>();
  listTenants: ShortTenant[] = [];

  changelog: UpdateChangelogModel = {};

  async created() {
    this.loadingChangelog = true
    await this.$http.myressif.changelogs
      .getById(this.changelogId)
      .then((res: Changelog) => {
        this.changelog = res;
        this.loadingChangelog = false;
      });

      await this.getTenants();
  }

  resetForm() {
    this.changelog = {};
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.changelogs
      .update(
        this.changelogId,
        this.changelog
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification du changelog",
            text: "Changelog modifié avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-changelogs");
        this.$emit("update:is-update-changelog-sidebar-active", false);
        this.loading = false;
      });
  }

  async searchChangelogTypes(params: any) {
    if (!params || params?.reset) {
      this.listChangelogTypes = new PaginatedList<ChangelogType>();
    }
    await this.$http.myressif.changelogTypes
      .paginatedList(
        params?.search ?? "",
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
      )
      .then((response: PaginatedList<ChangelogType>) => {
        this.listChangelogTypes.items = this.listChangelogTypes!.items.concat(
            response.items.filter(
              (ri) => !this.listChangelogTypes!.items.some((lsi) => lsi.id == ri.id)
            )
          );
        this.listChangelogTypes.totalCount = response.totalCount
      });
  }

  async getTenants() {
    await this.$http.myressif.tenants
      .list()
      .then((response: ShortTenant[]) => {
        this.listTenants = response
      });
  }
}
