













































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { Changelog } from "@/api/models/changelogs/changelog";
import CreateChangelog from "./CreateChangelog.vue";
import EditChangelog from "./EditChangelog.vue";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BAlert,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BAlert,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    CreateChangelog,
    EditChangelog,
  },
})
export default class ListChangelogs extends Vue {
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  isAddNewChangelogSidebarActive = false;
  isUpdateChangelogSidebarActive = false;
  selectedChangelogId = "";
  searchQuery = "";
  timeout: any = null;

  tableColumns = [{key: "date", sortable: true, label: "Date"}, { key: "description", sortable: true, label: "Description" }, { key: "actions" }];
  paginatedListChangelogs = new PaginatedList<Changelog>();

  async created() {
    await this.loadListChangelogs({
      search: this.searchQuery,
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListChangelogs.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListChangelogs.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListChangelogs({
      search: this.searchQuery,
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  selectChangelog(changelogId: string) {
    this.$router.push({
      name: "details-changelog",
      params: { id: changelogId },
    });
  }

  editChangelog(changelogId: any) {
    this.selectedChangelogId = changelogId;
    this.isUpdateChangelogSidebarActive = true;
  }

  @Watch('searchQuery')
  async searchChangelogs() {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.loadListChangelogs({
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        search: this.searchQuery,
      })
    }, 1000);
  }

  async loadListChangelogs(params: any) {
    if (!params || params?.reset) {
      this.paginatedListChangelogs = new PaginatedList<Changelog>();
    }
    await this.$http.myressif.changelogs
      .paginatedList(params?.search, params?.pageNumber ?? 1, params?.pageSize ?? 10)
      .then((response: PaginatedList<Changelog>) => {
        this.paginatedListChangelogs = response;
      });
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce changelog ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.changelogs
      .delete(id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un changelog",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-changelogs");
        this.loadListChangelogs({
          search: this.searchQuery,
          pageNumber: this.currentPage,
          pageSize: this.perPage,
        });
      });
  }
}
